/* eslint-disable node/no-process-env */
"use client";

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Client, {
  buildClient,
  Checkout,
  CheckoutLineItem,
  CheckoutLineItemInput,
  CheckoutLineItemUpdateInput,
} from "shopify-buy";

const apiVersion = "2023-01";
const domain =
  process.env.GATSBY_SHOPIFY_CHECKOUT_URL ??
  process.env.GATSBY_SHOPIFY_STORE_URL;
if (!domain) throw new Error("GATSBY_SHOPIFY_STORE_URL does not exist");

const storefrontAccessToken = process.env.GATSBY_STOREFRONT_API_ACCESS_TOKEN;
if (!storefrontAccessToken)
  throw new Error("GATSBY_STOREFRONT_API_ACCESS_TOKEN does not exist");

let client: Client | undefined;

const localStorageKey = `shopify_checkout_id`;
const localEmailKey = `shopify_email`;

interface IStoreContext {
  client: Client | undefined;
  checkout: Checkout;
  addVariantToCart: (
    variantId: string,
    title: string,
    quantity: number,
  ) => Promise<Checkout | undefined>;
  getLineItemForVariant: (variantId: string) => CheckoutLineItem | undefined;
  removeLineItem: (lineItemId: string) => Promise<void>;
  updateLineItem: (
    lineItemId: string,
    variantId: string,
    quantity: number,
  ) => Promise<CheckoutLineItem | undefined>;
  loading: boolean;
  didJustAddToCart: boolean;
  setEmail: (email: string) => void;
}

const defaultValues: IStoreContext = {
  client,
  checkout: { lineItems: [], lineItemCount: 0, id: 0 } as unknown as Checkout,
  addVariantToCart: () => Promise.resolve(undefined),
  getLineItemForVariant: () => undefined,
  removeLineItem: () => Promise.resolve(),
  updateLineItem: () => Promise.resolve(undefined),
  loading: false,
  didJustAddToCart: false,
  setEmail: () => undefined,
};

export const StoreContext = createContext(defaultValues);

async function handleCheckoutFunction(fn: () => Promise<unknown>) {
  try {
    const result = await fn();
    console.log("Got ok", result);
  } catch (error) {
    console.error("Got", error);
  }
}

async function addEmailAndDiscountsToCheckout(
  checkout: Checkout,
  email: string,
) {
  localStorage.setItem(localEmailKey, email);
  const c = client;
  if (!c) return;
  await handleCheckoutFunction(() =>
    c.checkout.updateEmail(checkout.id, email),
  );
  const discountCodeArray = process.env.GATSBY_SHOPIFY_DISCOUNT_CODES
    ? process.env.GATSBY_SHOPIFY_DISCOUNT_CODES.split(",")
    : undefined;
  const discountCodes =
    discountCodeArray && discountCodeArray.length > 0
      ? discountCodeArray
      : undefined;

  if (discountCodes) {
    for (const discountCode of discountCodes) {
      await handleCheckoutFunction(() =>
        c.checkout.addDiscount(checkout.id, discountCode),
      );
    }
  }
}

function findLineItem(
  cart: Checkout,
  variantId: string,
): CheckoutLineItem | undefined {
  return cart.lineItems.find((i) => i.variant && i.variant.id === variantId);
}

export const StoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  const setCheckoutItem = useCallback((checkout: Checkout) => {
    localStorage.setItem(localStorageKey, `${checkout.id}`);
    setCheckout(checkout);
  }, []);

  useEffect(() => {
    async function initialiseCheckout(client: Client) {
      const existingCheckoutId = localStorage.getItem(localStorageKey);
      if (existingCheckoutId && existingCheckoutId !== "null") {
        try {
          const existingCheckout =
            await client.checkout.fetch(existingCheckoutId);
          if (!existingCheckout?.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          console.error("Error getting existing checkout", e);
          // ignore
        }
      }
      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
      const existingEmail = localStorage.getItem(localEmailKey);
      if (existingEmail && existingEmail !== "null") {
        await addEmailAndDiscountsToCheckout(newCheckout, existingEmail);
      }
    }

    if (!client) {
      client = buildClient({ apiVersion, domain, storefrontAccessToken });
    }

    initialiseCheckout(client)
      .then(() => {
        /* noop */
      })
      .catch((e) => console.error("Failed to initialise checkout", e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addVariantToCart = useCallback(
    (variantId: string, title: string, quantity: number) => {
      if (!client) return Promise.resolve(undefined);
      setLoading(true);
      const lineItemsToUpdate: Array<CheckoutLineItemInput> = [
        {
          variantId,
          quantity,
          customAttributes: [{ key: "title", value: title }],
        },
      ];

      return client.checkout
        .addLineItems(checkout.id, lineItemsToUpdate)
        .then((res) => {
          setCheckout(res);
          setLoading(false);
          setDidJustAddToCart(true);
          setTimeout(() => setDidJustAddToCart(false), 3000);
          return res;
        })
        .catch((e) => {
          console.error("Error adding to cart", e);
          setLoading(false);
          return undefined;
        });
    },
    [checkout.id],
  );

  const removeLineItem = useCallback(
    (lineItemId: string) => {
      if (!client) return Promise.resolve(undefined);
      setLoading(true);
      return client.checkout
        .removeLineItems(checkout.id, [lineItemId])
        .then((res) => {
          setCheckout(res);
        })
        .catch((e) => {
          console.error("Error removing from cart", e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [checkout.id],
  );

  const updateLineItem = useCallback(
    (lineItemId: string, variantId: string, quantity: number) => {
      if (!client) return Promise.resolve(undefined);
      setLoading(true);
      const lineItemsToUpdate: Array<CheckoutLineItemUpdateInput> = [
        { id: lineItemId, variantId, quantity },
      ];
      return client.checkout
        .updateLineItems(checkout.id, lineItemsToUpdate)
        .then((res) => {
          setCheckout(res);
          return findLineItem(res, variantId);
        })
        .catch((e) => {
          console.error("Error updating cart", e);
          return undefined;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [checkout.id],
  );

  const getLineItemForVariant = useCallback(
    (variantId: string | number) => {
      return checkout.lineItems.find(
        (l) => l.variant && l.variant.id === variantId,
      );
    },
    [checkout.lineItems],
  );

  const setEmail = useCallback(
    (email: string) => {
      addEmailAndDiscountsToCheckout(checkout, email)
        .then(() => console.log("Added email"))
        .catch(console.error);
    },
    [checkout],
  );

  const value = useMemo(() => {
    return {
      ...defaultValues,
      checkout,
      loading,
      didJustAddToCart,
      addVariantToCart,
      removeLineItem,
      updateLineItem,
      getLineItemForVariant,
      setEmail,
    };
  }, [
    addVariantToCart,
    checkout,
    didJustAddToCart,
    loading,
    removeLineItem,
    updateLineItem,
    getLineItemForVariant,
    setEmail,
  ]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
