"use client";

import { graphql, useStaticQuery } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import { OutOfStockForm } from "../components/OutOfStockForm";

function setSeenInSession() {
  const key = "out-of-stock";
  window.sessionStorage.setItem(key, "true");
}

function isSeenInSession(popupId: string) {
  const key = popupId;
  const value = window.sessionStorage.getItem(key);
  const seen = value == "true";
  console.log(`isSeenInSession out-of-stock: ${seen.toString()}`);
  return seen;
}

async function handle(ac: AbortController) {
  const initialDelayInSeconds = 3;
  await abortableDelay(initialDelayInSeconds * 1000, ac.signal);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (!isSeenInSession("out-of-stock")) {
      return (
        <OutOfStockForm
          ok="Thanks, we'll be in touch once this item is back in stock."
          fail="That didn't work. You may already be signed up to receive emails from us."
        />
      );
    } else {
      // eslint-disable-next-line consistent-return
      return;
    }
  }
}

function abortableDelay(ms: number, signal: AbortSignal) {
  return new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line prefer-const
    let handle: number | undefined;
    const onCancel = () => {
      if (typeof handle !== "undefined") {
        window.clearTimeout(handle);
      }
      // eslint-disable-next-line prefer-promise-reject-errors
      reject();
    };
    signal.addEventListener("abort", onCancel, true);
    handle = window.setTimeout(() => {
      signal.removeEventListener("abort", onCancel, true);
      resolve();
    }, ms);
  });
}

export const PopupManager: React.FC = () => {
  const { site } = useStaticQuery<Queries.OutOfStockQuery>(graphql`
    query OutOfStock {
      site {
        siteMetadata {
          productsOutOfStock
        }
      }
    }
  `);

  const [popup, setPopup] = useState<React.JSX.Element>();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    handle(ac)
      .then((popup) => {
        if (popup) {
          setPopup(popup);
          setShowPopup(true);
        }
      })
      .catch((error) => {
        if (error) {
          console.log("error in manager", error);
        }
      });

    return () => {
      console.log("aborting ...");
      ac.abort();
    };
  }, []);

  const handleClose = useCallback(() => {
    setShowPopup(false);
    if (popup) {
      setSeenInSession();
    }
  }, [popup]);

  useEffect(() => {
    if (site?.siteMetadata?.productsOutOfStock && showPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showPopup, site?.siteMetadata?.productsOutOfStock]);

  if (!popup || !showPopup || !site?.siteMetadata?.productsOutOfStock)
    return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-OxlordBlue bg-opacity-70">
      <div className="flex w-full max-w-lg flex-col bg-GrannySmith px-l pb-xl pt-m shadow-lg">
        <button
          onClick={handleClose}
          className="ml-auto w-min p-xs text-right hover:bg-TropicalRainForest hover:bg-opacity-50"
        >
          X
        </button>
        {popup}
      </div>
    </div>
  );
};
