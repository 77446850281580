module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mulberrystreetseltzer.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mulberry Street Seltzer","short_name":"tisc","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"96b5855bee5d6cee3d174cf8416d54de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/@bond-london/gatsby-theme/gatsby-browser.ts'),
      options: {"plugins":[],"showDevPages":false,"isProduction":true,"projectName":"Mulberry Street Seltzer","useVideoCache":true,"videoCacheConnectionString":"DefaultEndpointsProtocol=https;AccountName=bondcache;AccountKey=V3vM2K9hSp1CWuueICom8jB2i5TiC6tisJLZUd6TCIaKMfXm5MR+rebcVLlgDUComIVTBp8ZXi1t+ASt7Uw9Sw==;EndpointSuffix=core.windows.net","videoWidth":1920,"graphCMSToken":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2Nzg3ODQxMjIsImF1ZCI6WyJodHRwczovL2FwaS1ldS13ZXN0LTIuaHlncmFwaC5jb20vdjIvY2xiZ25ldG5nMGl1aTAxdDE5cDI5Z3dwNC9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMzYyZDhlMzctY2VjMy00YzBiLWJmODUtODJiMTg4MzdjOTgyIiwianRpIjoiY2xmODBwejNlNndpOTAxdWpnN211OWFnZyJ9.K8SrCB4xk13vimqBD8IajegBbXii3pn7v5CE6fCAQYfyfdIRSuFkZDIJ603xNwl5yEEB_Cn5oGzeO686Gr5acw5mCoKXsbtdbZdMSXoiuzC0wI71oK4UkhQEuuzEtFb6ktAWV-YaWWLhJRQQMcXRYa34y1GwM7V3aoP-F7eT403PYV2D6ck-a8DxYyOVzdWnkJ9HskGNUlk5EPicOPXsXOOxeEZ_ksz7Sx35VJ4EEqAmpCK--VPoCkFdQXhY5NVs4aWqqj1hfhLF4T29KtyOrC5W8gJxQr2mck3jsm7A2oMum77wNpr6QELsyfk3eRD1FeGj59otjboOCR8w1jBoLmnka2rVFafV2571HuFQ7UbX6QmCegqLq1jNRk6bovrrBLYZ0rEvJAYjRXnhv3LJUYUiOY02laQi-iBAJfzdXteFwD_gb1LEbEFiDGyLV7wNmqTTXOIr-aNeBb3UWyIBwF9rCQFyrTA9Exwd1sEXXZCEd3x2IzDbfUUhxBnmE6BcEdcOVS5b9oMMN22js0Bh4uUSeDyvqTzbyblsw2lkiNQpc_58OuA70Vrz4wIvhS_DP_1GCBtzcxURaIG9oilccUwHOnXLCGF23wYlRLxdsqLT2ppdwp9ZdzZLE90LHkN4TgoBzIufoSqrydvdE44tXT0klpEjw-IM9aW2YEOLsEg","graphCMSEndpoint":"https://api-eu-west-2.hygraph.com/v2/clbgnetng0iui01t19p29gwp4/master","graphCMSStage":"PUBLISHED","enableEslint":false,"siteUrl":"https://mulberrystreetseltzer.com","icon":"src/images/favicon.png","allowIndex":true,"manifestOptions":{"name":"Mulberry Street Seltzer","short_name":"tisc","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
