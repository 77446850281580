exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-graph-cms-article-article-type-slug-graph-cms-article-slug-tsx": () => import("./../../../src/pages/{GraphCMS_Article.articleType__slug}/{GraphCMS_Article.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-article-article-type-slug-graph-cms-article-slug-tsx" */),
  "component---src-pages-graph-cms-page-slug-tsx": () => import("./../../../src/pages/{GraphCMS_Page.slug}.tsx" /* webpackChunkName: "component---src-pages-graph-cms-page-slug-tsx" */),
  "component---src-pages-products-shopify-product-handle-tsx": () => import("./../../../src/pages/products/{ShopifyProduct.handle}.tsx" /* webpackChunkName: "component---src-pages-products-shopify-product-handle-tsx" */),
  "component---src-templates-article-type-cocktails-list-tsx": () => import("./../../../src/templates/articleType/cocktails/list.tsx" /* webpackChunkName: "component---src-templates-article-type-cocktails-list-tsx" */),
  "component---src-templates-article-type-press-list-tsx": () => import("./../../../src/templates/articleType/press/list.tsx" /* webpackChunkName: "component---src-templates-article-type-press-list-tsx" */),
  "component---src-templates-shopify-product-list-tsx": () => import("./../../../src/templates/shopifyProductList.tsx" /* webpackChunkName: "component---src-templates-shopify-product-list-tsx" */),
  "slice---src-components-analytics-tsx": () => import("./../../../src/components/Analytics.tsx" /* webpackChunkName: "slice---src-components-analytics-tsx" */)
}

