import classNames from "classnames";
import React from "react";

export const ProgressDots: React.FC<{ count: number; className?: string }> = ({
  count,
  className,
}) => {
  return (
    <div
      className={classNames("h5 flex gap-x-s self-center px-m", className)}
      data-component="Progress dots"
    >
      {Array.from({ length: count }).map((_, i) => (
        <svg
          viewBox="0 0 1 1"
          width="1"
          height="1"
          key={i}
          className="h-line-height w-auto animate-pulse fill-current animation-duration-1000"
          style={{ animationDelay: `${i * 300}ms` }}
        >
          <circle cx="0.5" cy="0.5" r="0.5" />
        </svg>
      ))}
    </div>
  );
};
