import { WrapRootElementNodeArgs } from "gatsby";
import React from "react";

import { PopupManager } from "@/layouts/PopupManager";
import WrapWithStore from "./src/layouts/WrapWithStore";
import "./src/styles/index.css";

// eslint-disable-next-line import/no-unused-modules
export function wrapRootElement({ element }: WrapRootElementNodeArgs) {
  return (
    <WrapWithStore>
      <PopupManager />
      {element}
    </WrapWithStore>
  );
}
