import React from "react";
import { useFormState } from "../../src/utils";
import { ProgressDots } from "./ProgressDots";

export const OutOfStockForm: React.FC<{
  ok: string;
  fail: string;
}> = ({ ok, fail }) => {
  const { state, status, handleChange, onSubmit } = useFormState(
    "/api/email-subscription",
    {
      email: "",
      consent: "",
      name: "",
      surname: "",
    },
    "UserSignup",
  );

  if (status === "Success") {
    return <p className="p2 self-center">{ok}</p>;
  }

  if (status === "Failure") {
    return <p className="p2 self-center">{fail}</p>;
  }

  if (status === "Submitting") {
    return <ProgressDots count={3} className="self-center" />;
  }

  return (
    <div className="flex flex-col gap-y-s">
      <h3 className="h3 italic">Oops! We&apos;re sold out.</h3>
      <p className="p3">But don&apos;t worry, new stock is on the way!</p>
      <p className="p3">
        Sign up for updates below, and we&apos;ll let you know as soon as
        it&apos;s back. 📦
      </p>
      <form className="flex flex-col gap-y-xs" onSubmit={onSubmit}>
        <input
          type="email"
          placeholder="Your Email*"
          name="email"
          value={state.email || ""}
          onChange={handleChange}
          className="p3 col-start-1 row-start-1 border-0 border-b-2 border-current bg-transparent p-0 placeholder:opacity-50 focus:border-current focus:ring-current"
          required
        />
        <label>
          <input
            type="checkbox"
            checked={!!state.consent}
            onChange={handleChange}
            name="consent"
            aria-label="Consent"
            className="pDefault mr-xs border-2 border-current bg-transparent"
            required
          />
          I consent to the data treatment and{" "}
          <a href="/privacy-policy">privacy policy</a>.
        </label>

        <button
          type="submit"
          className="hover:bg-green-700 button buttonSmall mt-m w-min whitespace-nowrap bg-TropicalRainForest italic text-GrannySmith"
        >
          sign up
        </button>
      </form>
    </div>
  );
};
