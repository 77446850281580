/* eslint-disable import/no-unused-modules */
import { IBondImage } from "@bond-london/gatsby-theme";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ContentFragment } from "./cms/CmsContent";
import { StoreContext } from "./layouts/StoreContext";

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === null || value === undefined) return false;
  return true;
}

export function arrayOrUndefined<T>(array?: ReadonlyArray<T>) {
  if (array && array.length > 0) {
    return array;
  }
  return undefined;
}

export function convertText(text: string) {
  if (text) {
    const replaced = text.replace(/&nbsp;/g, "\u00a0");
    const split = replaced.split("<br>");
    if (split.length === 1) return replaced;

    const mapped = split.map((s, index) => (
      <>
        {index > 0 && <br />}
        <span>{s}</span>
      </>
    ));
    return mapped;
  }
  return undefined;
}

export function bytesToHex(bytes: Uint8Array) {
  return Array.from(bytes, (b) => b.toString(16).padStart(2, "0")).join("");
}

export function stringToUTF8Bytes(string: string) {
  return new TextEncoder().encode(string);
}

export function removeImagePlacholder(image?: IBondImage) {
  if (image) {
    return {
      ...image,
      image: image.image
        ? { ...image.image, placeholder: undefined, backgroundColor: undefined }
        : undefined,
    };
  }
  return undefined;
}

export function combineComponents(
  ...components: ReadonlyArray<
    ReadonlyArray<ContentFragment | null> | null | undefined
  >
): ReadonlyArray<ContentFragment> {
  const result: Array<ContentFragment> = [];
  components?.forEach((c) => {
    c?.forEach((i) => {
      if (i) {
        result.push(i);
      }
    });
  });
  return result;
}
async function submitForm(
  e: React.FormEvent<HTMLFormElement>,
  setStatus: (s: FormStatus) => void,
  setEmail: (email: string) => void,
  localStorageKey?: string,
) {
  e.preventDefault();
  setStatus("Submitting");
  try {
    const form = e.currentTarget;
    const formData = new FormData(form);
    const json = JSON.stringify(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.fromEntries(formData as unknown as Array<any>),
    );
    if (localStorageKey) {
      window.localStorage.setItem(localStorageKey, json);
    }
    const consent = formData.get("consent") as string;
    if (consent) {
      const response = await fetch("/api/email-subscription", {
        body: json,
        method: "POST",
        headers: { "content-type": "application/json" },
      });
      if (response.ok) {
        setStatus("Success");
        const email = formData.get("email")?.toString();
        if (email) {
          setEmail(email);
        }
      } else {
        const result = await response.text();
        console.error("Error", result, response.statusText);
        setStatus("Failure");
      }
    }
  } catch (error) {
    console.error("Error", error);
    setStatus("Failure");
  }
}
type FormStatus = "Active" | "Submitting" | "Success" | "Failure";
type FormState = Record<string, string>;
export function useFormState(
  url: string,
  initial: FormState,
  localStorageKey?: string,
) {
  const { setEmail } = useContext(StoreContext);
  const [state, setState] = useState(initial);
  const [status, setStatus] = useState<FormStatus>("Active");

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue =
        ev.target.type === "checkbox"
          ? ev.target.checked
            ? "on"
            : ""
          : ev.target.value;

      setState((current) => ({
        ...current,
        [ev.target.name]: newValue,
      }));
    },
    [],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void submitForm(e, setStatus, setEmail, localStorageKey);
    },
    [localStorageKey, setEmail],
  );

  useEffect(() => {
    if (localStorageKey) {
      const current = window.localStorage.getItem(localStorageKey);
      if (current) {
        const value = JSON.parse(current) as FormState;
        setState(value);
      }
    }
  }, [localStorageKey]);

  return { state, status, handleChange, onSubmit };
}
